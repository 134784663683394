<template>
  <div class="governance common-page">
    <div v-if="componentName == 'default'">
      <header class="page-header">
        <div class="header-left">
          <div class="title-instruction"></div>
          <span class="header-title">治理</span>
        </div>
        <div class="header-right"></div>
      </header>
      <section class="page-content">
        <div class="search-wrapper">
          <div class="search-title">违章筛选</div>
          <Divider class="divider-wrapper" size="small" />
          <div class="search-content">
            <div class="search-item">
              <RadioText @changeRadio="governTypeChange" :radio="tempGovernStatus" :defaultRadio="searchForm.governType" title="治理单状态:" labelWidth="80"></RadioText>
            </div>
            <div class="search-item">
              <Form :model="searchForm" :label-width="0" label-position="left" inline>
                <FormItem label="企业名称:" :label-width="80">
                  <AutoComplete v-model="searchForm.unitName" @on-focus="unitSearch" @on-search="unitSearch" @on-select="unitSelect" @on-clear="clearAutoComplete" :clearable="true" placeholder="请选择企业" style="width:200px">
                    <Option v-for="item in unitArray" :value="`${item.unitName}|${item.unitId}`" :key="item.unitId">{{ item.unitName }}</Option>
                  </AutoComplete>
                </FormItem>
                <!-- <FormItem label="通报周期" :label-width="68">
                  <el-date-picker
                    v-model="searchForm.time"
                    type="week"
                    format="yyyy 第 WW 周"
                    placeholder="选择周"
                    :picker-options="{ firstDayOfWeek: 1 }"
                    :clearable="false"></el-date-picker>
                </FormItem> -->
                <FormItem label="违章时间" :label-width="68">
                  <Date-picker v-model="searchForm.time" format="yyyy/MM/dd" type="daterange" placeholder="选择日期" style="width: 200px;"></Date-picker>
                </FormItem>
                <FormItem>
                  <Input v-model="searchForm.keyword" placeholder="治理单号/车牌号"/>
                </FormItem>
                <FormItem>
                  <Button v-if="btnPrivilegeCode.governance.includes('read')" @click="getTotalData('search')" :loading="searchLoading" class="form-btn" type="primary">查询</Button>
                </FormItem>
              </Form>
            </div>
          </div>
        </div>
        <div class="table-wrapper">
          <Spin size="large" fix v-if="searchLoading"></Spin>
          <Table :columns="tableColmuns" :data="tableData" size="small">
            <template slot-scope="{ row }" slot="vehicle">
              {{`${row.vehicleNo}(${row.vehiclePlateColor})`}}
            </template>
            <template slot-scope="{ row }" slot="routeHop">
              <span v-if="Number(row.remainingVehicle) > 0" class="btn-span">{{ row.remainingVehicle }}</span>
              <span v-else>{{ row.remainingVehicle }}</span>
            </template>
            <template slot-scope="{ row }" slot="action">
              <span v-if="btnPrivilegeCode.governance.includes('Details')" @click="openDetail(row)" class="btn-span">查看</span>
              <span v-if="filterAction(row) && btnPrivilegeCode.governance.includes('Govern')" @click="govern(row)" class="btn-span">治理</span>
            </template>
          </Table>
          <div class="pagenation-wrapper">
            <Page
              :current="paginationConfig.currentPage"
              :page-size="paginationConfig.pageSize"
              :total="paginationConfig.total"
              @on-change="currentPageChange"
              show-total
              show-elevator
              size="small"/>
          </div>
        </div>
      </section>
    </div>
    <!-- 治理详情 -->
    <div v-else-if="componentName == 'GovernDetail'">
      <GovernDetail v-if="componentName == 'GovernDetail'" :params="{targetRow: clickRow, backTitle: '治理', backComponentName: 'default'}" @goBack="changeComponentName" @reSetGoverns="reSetGoverns"></GovernDetail>
    </div>
    <!-- 编辑治理单 -->
    <div v-else-if="componentName == 'GovernEdit'">
      <GovernEdit v-if="clickRow && componentName == 'GovernEdit'" :params="{governId: clickRow.governId, backTitle: '治理', backComponentName: 'default'}"  @goBack="changeComponentName" @reSetGoverns="reSetGoverns"></GovernEdit>
    </div>
    <!-- 添加治理单 -->
    <div v-else-if="componentName == 'IllegalCreate'">
      <IllegalCreate v-if="componentName == 'IllegalCreate'" :params="{...targetGovernParams, backTitle: '治理', backComponentName: 'default'}" :isShowPrevStepBtn="false" @goBack="changeComponentName"></IllegalCreate>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { getTimeStrByType } from '@/assets/js/common.js'
import {btnPrivilegeMixin, unitSearchMixin, paginationMixin, privilegeMixin, paramsMixin, alarmTableMixin, pageChangeMixin, userInfoMixin} from '@/assets/js/mixin.js'
import RadioText from '@/components/radio-text/index.vue'
import GovernDetail from './govern_detail/index.vue'
import IllegalCreate from '../illegal/illegal_create/index.vue'
import GovernEdit from './govern_edit/index.vue'
export default {
  mixins: [btnPrivilegeMixin, unitSearchMixin, paginationMixin, privilegeMixin, paramsMixin, alarmTableMixin, pageChangeMixin, userInfoMixin],
  name: 'governance',
  components: {
    RadioText,
    GovernDetail,
    IllegalCreate,
    GovernEdit
  },
  data () {
    return {
      searchForm: {
        unitName: '',
        unitId: '',
        noticeId: '',
        time: '',
        governType: [0],
        filterVehicle: '',
        notificateTime: '',
        keyword: ''
      },
      searchLoading: false,
      tableColmuns: [
        {type: 'index', title: '序号', width: 60, align: 'center'},
        {title: '企业名称', key: 'unitName', align: 'center'},
        // {title: '所属通报', key: 'noticeName', align: 'center'},
        // {title: '通报周期', key: 'noticePeriod', align: 'center'},
        {title: '治理单', key: 'governId', align: 'center'},
        {title: '治理状态', key: 'governStatus', align: 'center'},
        {title: '治理车辆', slot: 'vehicle', align: 'center'},
        {title: '治理提交时间', key: 'createTime', align: 'center'},
        {title: '操作', slot: 'action', align: 'center'}
      ],
      tableData: [],
      clickRow: null,
      // 治理
      targetGovernParams: {
        noticeId: '',
        beginTime: '',
        endTime: '',
        step: 1,
        governWay: 2,
        vehicle: ''
      },
    }
  },
  computed: {
    tempGovernStatus () {
      return [
        {label: '全部', value: 0},
        ...this.governStatus
      ]
    }
  },
  methods: {
    async getTotalData (type) {
      if (!this.searchForm.time) {
        this.$Message.warning('请选择周期时间')
        return
      }
      if (type == 'search') {
        this.paginationConfig.currentPage = 1
      }
      let _params = {
        appKey: this.userInfo.appKey,
        unitId: this.searchForm.unitId ? this.searchForm.unitId : 0,
        beginTime: getTimeStrByType(this.searchForm.time[0], 3),
        endTime: getTimeStrByType(this.searchForm.time[1], 3),
        keyword: this.searchForm.keyword,
        // noticeId: this.searchForm.noticeId,
        status: this.searchForm.governType[0],
        pageIndex: this.paginationConfig.currentPage,
        pageSize: this.paginationConfig.pageSize,
      }
      this.searchLoading = true
      try {
        let {data} = await axios({
          method: 'post',
          url: `${WEB_CONFIG.BASE_IP}/govern-web/govern/pageList`,
          data: _params
        })
        this.searchLoading = false
        this.isSearched = true
        if (data.code === 200) {
          this.tableData = data.data.records
          this.paginationConfig.total = data.data.total
        } else {
          this.$Message.warning(data.msg)
        }
      } catch (error) {
        this.searchLoading = false
        if (!this.checkPrivilege(error)) {
          this.$Message.error('获取治理信息失败, 请稍后重试!')
        }
      }
    },
    // 判断治理按钮显示权限
    filterAction (row) {
      let result = false
      // 治理中 治理待审核 治理未提交 治理未通过 都显示治理按钮
      if (row.status == 3 || row.status == 4 || row.status == 5 || row.status == 7) {
        result = true
      }
      return result
    },
    // 查看治理详情
    openDetail (row) {
      this.clickRow = row
      this.componentName = 'GovernDetail'
    },
    // 治理
    govern (row) {
      this.clickRow = row
      if (row.status <= 0) {
        // 治理单未生成 则跳转到违章生成页面
        // this.targetGovernParams.noticeId = this.searchForm.noticeId
        if (this.searchForm.time) {
          // 如果查询时间存在 则将查询开始结束时间传过去
          this.targetGovernParams.beginTime = getTimeStrByType(this.searchForm.time[0], 3)
          this.targetGovernParams.endTime = getTimeStrByType(this.searchForm.time[1], 3)
        } else {
          // 没有 则从当前月1号至今
          this.targetGovernParams.beginTime = getTimeStrByType(new Date(), 4) + '-01'
          this.targetGovernParams.endTime = getTimeStrByType(new Date(), 3)
        }
        this.targetGovernParams.step = 1
        this.targetGovernParams.vehicle = `${row.vehicleNo}-${row.vehiclePlateColor}`
        this.componentName = 'IllegalCreate'
      } else {
        // 直接打开治理编辑页面
        this.componentName = 'GovernEdit'
      }
    },
    // 违章类型改变
    alarmTypeChange (target) {
      this.alarmType = target
    },
    // 治理单状态改变
    governTypeChange (target) {
      this.searchForm.governType = target
    },
    // 重开罚单
    reSetGoverns (params) {
      this.targetGovernParams.step = 1
      this.targetGovernParams.beginTime = params.beginTime
      this.targetGovernParams.endTime = params.endTime
      this.targetGovernParams.vehicle = params.vehicle
      this.componentName = 'IllegalCreate'
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/common.scss';
@include common-table-page();
.governance {
  .page-content {
    .search-wrapper {
      padding-left: 8px;
      .search-content {
        .search-item {
          margin: 10px 0;
          .form-btn {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>